import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { LOGIN_ROUTE } from '@app/constants/routes';

const Page404 = () => {
  const router = useRouter();

  useEffect(() => {
    router.replace(LOGIN_ROUTE);
  });

  return null;
};

export default Page404;
